<template>
  <NavQuestion
    :number="number"
    v-bind:heading="'Telephone Notification (Optional)'"
    :subHeading="subTitle"
    v-bind:background="'bg-basket'"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="row mt30">
          <div
            v-for="(person, index) in people"
            v-bind:key="index"
            class="col-lg-4 col-md-4 col-sm-6 col-xs-12"
          >
            <div
              v-bind:class="{ selected: person.share_broker }"
              class="input-option input-option-share"
              v-on:click="select(person)"
            >
              <div class="tick-wrapper"></div>
              <span class="tick ticked"></span>
              <div class="image-wrapper">
                <img alt="male" v-bind:src="genderPicture(person)" />
              </div>
              <div class="person-details">
                <p>{{ person.full_name }}</p>
                <div class="contact-details">
                  <form
                    autocomplete="off"
                    name="person.full_name"
                    v-on:submit.prevent
                  >
                    <div class="input-container">
                      <input
                        @click.stop
                        @input="inputPhone(person, $event)"
                        :value="person.phone"
                        v-bind:required="person.share_broker"
                        autocomplete="false"
                        class="input-standard-grey validation"
                        max="15"
                        maxlength="15"
                        :name="'email' + index"
                        :id="'email' + index"
                        placeholder="Phone number"
                        type="text"
                      />
                    </div>

                    <divider :text="'AND/OR'" />

                    <div class="input-container mb30">
                      <input
                        @click.stop
                        @input="inputEmail(person, $event)"
                        :value="person.email"
                        v-bind:required="person.share_broker"
                        autocomplete="false"
                        class="input-standard-grey validation"
                        max="15"
                        maxlength="60"
                        :name="'phone' + index"
                        :id="'phone' + index"
                        placeholder="Email address"
                        type="text"
                      />
                    </div>
                    <ErrorPop
                      v-show="
                        person.share_broker &&
                        !person.email &&
                        !person.phone &&
                        show.errors
                      "
                      :message="'Phone or email required'"
                      v-bind:displayClose="false"
                    ></ErrorPop>

                    <p style="margin: 25px">Contact this person?</p>
                    <div class="input-container" style="height: 65px">
                      <InputSwitch
                        style="left: calc(50% - 103px)"
                        :checked="person.share_broker"
                        :small="false"
                        @toggle="select(person)"
                      ></InputSwitch>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card-form">
          <div class="row mt30">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <p class="mb30">
                Where contact is requested the details will be sent to the below
                email address to action, the details ARE NOT accessible by any
                other means in the future. Once notification has been completed
                please securely delete the email.
              </p>
              <div class="input-container">
                <form
                  autocomplete="off"
                  name="broker_contact_details"
                  v-on:submit.prevent
                >
                  <input
                    v-model="form.brokerEmail"
                    type="text"
                    id="contact-email"
                    name="contact-email"
                    class="input-standard-grey validation"
                    required
                    placeholder="Your Contact Email"
                  />
                  <label for="contact-email"> Your Contact Email </label>
                  <ErrorPop
                    v-show="show.brokerContactError"
                    :message="contactErrorMessage"
                    :displayClose="true"
                    @clearError="contactErrorMessage = null"
                  ></ErrorPop>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="validateInput"
        v-bind:forwardText="'Next'"
        v-bind:backLink="'/home'"
        v-bind:forwardLink="'/handover/account'"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import { functions } from '@/common/mixins/functions'
import { TextFormat } from '../../../../common/mixins/TextFormat'
import { willStringHelpers } from '../../../../common/mixins/willStringHelpers'
import InputSwitch from '@/common/ui/InputSwitch'
import Divider from '../../../../common/ui/Divider'
import ErrorPop from '../../../../common/ui/ErrorPop'

export default {
  name: 'ContactHandoverPage',
  mixins: [functions, TextFormat, willStringHelpers],
  components: {
    NavQuestionButtons,
    NavQuestion,
    InputSwitch,
    Divider,
    ErrorPop
  },
  mounted() {
    if (this.$store.getters.user)
      this.form.email = this.$store.getters.user.email
    if (this.$store.getters.handoverBrokerContactEmail)
      this.form.brokerEmail = this.$store.getters.handoverBrokerContactEmail
    else if (
      this.checkoutBasket &&
      this.checkoutBasket.coupon &&
      this.checkoutBasket.coupon.broker &&
      this.checkoutBasket.coupon.broker.contact_email
    )
      this.form.brokerEmail = this.checkoutBasket.coupon.broker.contact_email
    if (this.personalDetails && this.personalDetails.phone)
      this.form.phone = this.personalDetails.phone
  },
  data() {
    return {
      form: {
        brokerEmail: null
      },
      error: {
        email: null,
        phone: null
      },
      loading: false,
      show: {
        success: false,
        errors: false,
        brokerContactError: false
      },
      validated: false,
      subTitle:
        'Ask the client if they would like you to contact each of the Executors & Trustees (not guardians) \n' +
        'named below to explain their role and responsibilities (note that when the client completes their \n' +
        'Will they can have an additional email / SMS notification sent). By default the below people are \n' +
        'entitled to a 25% discount if they want to make their Will, we suggest offering a further discount \n' +
        'as part of the notification process.',
      contactErrorMessage: null
    }
  },
  watch: {},
  beforeRouteLeave(to, from, next) {
    if (to.name === 'AccountHandoverPage') {
      if (this.validatePage()) {
        this.saveShareStatus()
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  computed: {
    validateInput() {
      return (
        (this.validEmail(this.form.brokerEmail) &&
          this.people.filter((person) => person.share_broker).length) ||
        !this.people.filter((person) => person.share_broker).length
      )
    },
    tempAccount() {
      return this.user && this.user.temp_account && this.user.change_password
    },
    number() {
      return '1'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    user() {
      return this.$store.getters.user
    },
    people() {
      return this.$store.getters.shareBrokerPeopleOptions
    },
    checkoutBasket() {
      return this.$store.getters.checkoutBasket
    }
  },
  methods: {
    genderPicture(person) {
      if (person.gender === 'Male')
        return 'https://mylastwill.s3.amazonaws.com/static/img/button-male.png'
      return 'https://mylastwill.s3.amazonaws.com/static/img/button-female.png'
    },
    inputPhone(person, event) {
      var personCopy = Object.assign({}, person)
      personCopy.phone = event.target.value
      this.$store.commit('personAdd', personCopy)
    },
    inputEmail(person, event) {
      var personCopy = Object.assign({}, person)
      personCopy.email = event.target.value
      this.$store.commit('personAdd', personCopy)
    },
    select(person) {
      var personCopy = Object.assign({}, person)
      personCopy.share_broker = !personCopy.share_broker
      personCopy.share = !personCopy.share_broker
      this.$store.commit('personAdd', personCopy)
    },
    saveShareStatus() {
      this.$store.dispatch('savePeople')
      this.$store.commit('handoverBrokerContactEmail', this.form.brokerEmail)
    },
    validatePage(person = null) {
      var temp = true
      for (var i = 0; i < this.people.length; i++) {
        if (
          this.people[i].share_broker === true &&
          (this.people[i].email === '' || !this.people[i].email) &&
          (this.people[i].phone === '' || !this.people[i].phone)
        ) {
          temp = false
        }
        if (this.form.brokerEmail === this.people[i].email) {
          this.show.brokerContactError = true
          this.contactErrorMessage =
            'Contact Email must not be one of the executors/trustees'
          temp = false
        }
      }
      if (this.form.brokerEmail === this.$store.getters.user.email) {
        this.show.brokerContactError = true
        this.contactErrorMessage =
          'Contact Email must not be the same as the clients'
        temp = false
      }
      this.show.errors = true
      this.validated = temp
      return temp
    }
  }
}
</script>

<style scoped>
.width-full {
  max-width: 100%;
}

.check {
  display: inline-block;
  font-size: 15px;
  margin-left: 10px;
}

.error-text {
  font-size: 15px;
  color: #e56781;
  white-space: nowrap;
}

.error {
  color: #e56781;
  border-top: 1px solid #e56781 !important;
  border-left: 1px solid #e56781 !important;
  border-bottom: 1px solid #e56781 !important;
  border-right: 5px solid #e56781 !important;
}

.card-form {
  border-radius: 10px 10px 10px 10px;
  -webkit-box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 10px 50px;
  background-color: white;
}

.notification-text {
  background-color: beige;
  padding: 30px;
  border-radius: 10px;
}

@media screen and (max-width: 570px) {
  .card-form {
    padding: 20px;
    border-radius: 0;
  }

  .notification-text {
    padding: 20px;
    border-radius: 0;
  }
}

.heading {
  font-family: 'Vollkorn', serif;
  line-height: 1.3;
  margin: 0;
  font-weight: 600;
  color: #2f2c2c;
}
</style>
