<template>
  <div class="divider">
    <span class="divider-border"></span>
    <span class="divider-text">{{ text }}</span>
    <span class="divider-border"></span>
  </div>
</template>

<script>
export default {
  name: 'Divider',
  props: ['text']
}
</script>

<style scoped>
.divider {
  width: 100%;
  position: relative;
  display: block;
  margin: 15px 0;
  clear: both;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.divider-border {
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
}
.divider .after,
.divider .before {
  position: relative;
  display: block;
  width: 100%;
}
.divider-text {
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  background: #fff;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 0.9375em;
}
</style>
